<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="900px"
        transition="dialog-transition"
        persistent
    >
    <v-card>
        <v-toolbar color="secondary" dark>
            Información de Reposicion/Renovacion de Credencial
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('cerrar')"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>    
        <v-card-text class="pt-5">
            <v-row  v-if="!loading">
                <v-col cols="12" lg="3" md="3" xs=6>
                    <v-img v-if="reposicion.foto!=null" :src="$docUrl+reposicion.foto" aspect-ratio="1.5" contain />
                    <v-img v-if="reposicion.firma!=null" :src="$docUrl+reposicion.firma" aspect-ratio="2.8" contain/>
                </v-col>
                <v-col cols="12" lg="9" md="9" xs="6">
                    <v-row>
                        <v-col cols="6">
                            <h5 class="grey--text">NOMBRE COMPLETO:</h5>
                            <p class="body-2">{{nombreCompleto}}</p>
                            <h5 class="grey--text">SERIE:</h5>
                            <p class="body-2">{{reposicion.serie}}</p>
                            <h5 class="grey--text">ESTADO CREDENCIAL:</h5>
                            <p class="body-2">
                                <v-chip
                                    :color="getColorEstadoCredencial(reposicion.estadoCredencial)"
                                    text-color="white"
                                    small
                                >
                                    {{reposicion.estadoCredencial}}
                                </v-chip>
                            </p>
                        </v-col>
                        <v-col cols="6">
                            <h5  class="grey--text">DEPARTAMENTO SOLICITUD:</h5>
                            <p class="body-2">{{reposicion.departamento}}</p>
                            <h5 class="grey--text">FECHA SOLICITUD:</h5>
                            <p class="body-2">{{formatDateText(reposicion.fechaSolicitud)}}</p>
                            <h5 class="grey--text">TIPO EMISION:</h5>
                            <p class="body-2">
                                <v-chip
                                    :color="getColorTipoCredencial(reposicion.activo)"
                                    text-color="white"
                                    small
                                >
                                    {{textoTipo(reposicion.tipoCredencial)}}
                                </v-chip>
                            </p>
                        </v-col>
                    </v-row>
                    <codigo-pago v-if="cpt!=null" :cpt="cpt"/>
                    <qr-pago v-if="qr!=null" :qr="qr"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
        
    </v-dialog>
</template>
<script>
import Mreposicion from "@/mixins/reposicion"
import MDateTime from "@/mixins/datetime"
import {mapGetters} from 'vuex'
export default {
    props:{
        idReposicion:{
            type: Number,
            default: -1
        },
        dialog:{
            type: Boolean,
            default: false
        }
    },
    components:{
        //'foto-firma':()=>import("@/components/FotoFirma"),
        'codigo-pago':()=>import("@/components/Cpt"),
        'qr-pago':()=>import("@/components/QrPago")

    },
    mixins:[Mreposicion,MDateTime],
    data(){
        return{
            loading:true,
            reposicion:null,
            cpt:null,
            qr:null
        }
    },
    computed:{
        ...mapGetters('abogado',{nombreCompleto:'getNombreCompleto'}),
    },
    async created(){
        await this.getInfoReposicion(this.idReposicion)
    }

}
</script>